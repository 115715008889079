/* eslint-disable @typescript-eslint/no-explicit-any */
import 'react-native-gesture-handler';
import React, { Suspense, useEffect } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { getDefaultRoute, routes } from './src/routes/routes';
import TokenStatus from './src/components/utils/tokenStatus';
import CompaniesContextProvider, { useCompaniesContext, } from './src/contexts/companies';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { getI18nInstance } from './src/translations';
import { Platform } from 'react-native';
import { ChakraProvider } from '@chakra-ui/react';
import LoadingAnimation from './src/components/utility/loading';
import _ from 'lodash';
import SplashScreen from 'react-native-splash-screen';
// import Hotjar from '@hotjar/browser';
import { v4 as uuidv4 } from 'uuid';
import theme from './theme';
if (Platform.OS === 'web') {
    // @ts-expect-error : WebPlatform is not defined in react-native-web
    window._frameTimestamp = null;
}
/**
 *
 * Govrn mobile app.
 */
const App = () => {
    // Attributes
    const queryClientRef = React.useRef();
    // Setup the query client
    if (queryClientRef.current == null) {
        queryClientRef.current = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchIntervalInBackground: true,
                    refetchOnReconnect: false,
                    refetchOnMount: true,
                    refetchOnWindowFocus: false,
                },
            },
        });
    }
    // Effects
    useEffect(() => {
        // CodePush.sync();
        // RNPdftron.initialize(Config.PDFTRON_LICENCE_KEY);
        // RNPdftron.enableJavaScript(true);
        const newLink = document.createElement('link');
        newLink.rel = 'shortcut icon';
        newLink.type = 'image/x-icon';
        newLink.href =
            'https://www.govrn.com/web/image/website/1/favicon?unique=cbf8f05';
        document.head.appendChild(newLink);
        if (process.env.NODE_ENV === 'development')
            return;
        // Setup hotjar
        // const siteId = Number(process.env.HOTJAR_SITE_ID);
        // const hotjarVersion = 6;
        // Hotjar.init(siteId, hotjarVersion);
        let visitorId = localStorage.getItem('Pendo-Visitor-Id');
        // Si le visitorId n'existe pas, en générer un nouveau
        if (!visitorId) {
            visitorId = uuidv4();
            // Stocker le visitorId dans le localStorage
            localStorage.setItem('Pendo-Visitor-Id', visitorId);
        }
        const pendoScript = document.createElement('script');
        const pendoScriptCode = document.createTextNode(`  
    (function(apiKey){
      (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
      v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
          o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
          y=e.createElement(n);y.async=!0;y.src='https://cdn.eu.pendo.io/agent/static/'+apiKey+'/pendo.js';
          z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
  
          // This function creates visitors and accounts in Pendo
          // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
          // Please use Strings, Numbers, or Bools for value types.
          pendo.initialize({
              visitor: {
                  id: '${visitorId}'
              }
          });
    })('${process.env.PENDO_API_KEY}');`);
        pendoScript.appendChild(pendoScriptCode);
        document.head.appendChild(pendoScript);
        SplashScreen.hide(); // hides the splash screen on app load.c
    }, []);
    const config = {
        screens: {
            '/auth': {
                path: '/auth',
                screens: {
                    '/auth/sign-in': {
                        path: '/sign-in',
                        screens: {
                            '/auth/sign-in/default': '',
                            '/auth/sign-in/forgot-password': {
                                path: '/forgot-password',
                                screens: {
                                    '/auth/sign-in/forgot-password/forgot-password-mail': '',
                                    '/auth/sign-in/forgot-password/forgot-password-create-new-password': '/new-password',
                                },
                            },
                        },
                    },
                    '/auth/sign-up': {
                        path: '/sign-up',
                        screens: {
                            '/auth/sign-up/default': {
                                path: '/:invitationId',
                            },
                            '/auth/sign-up/invitation-expired': '/invitation-expired',
                            '/auth/sign-up/create-account': '/create-account',
                        },
                    },
                    '/auth/verification-code': {
                        path: '/verification-code',
                    },
                    '/auth/create-password': '/create-password',
                },
            },
            '/company': {
                path: '/company/:companyId',
                screens: {
                    '/company/home': {
                        path: '/home',
                        screens: {
                            '/company/home/default': '',
                            '/company/home/feed': {
                                path: '/feed',
                                initialRouteName: '/company/home/feed/default',
                                screens: {
                                    '/company/home/feed/default': '',
                                    '/company/home/feed/share-post': '/share-post',
                                },
                            },
                            '/company/home/tasks': '/tasks',
                            '/company/home/calendar': {
                                path: '/calendar',
                                initialRouteName: '/company/home/calendar/default',
                                screens: {
                                    '/company/home/calendar/default': '',
                                    '/company/home/calendar/event-details': '/event-details',
                                    '/company/home/calendar/boardpack': '/boardpack',
                                },
                            },
                            '/company/home/messages': {
                                path: '/messages',
                                screens: {
                                    '/company/home/messages/default': '',
                                },
                            },
                            '/company/home/ai-assist': '/ai-assist',
                            '/company/home/search': '/search',
                            '/company/home/more': {
                                path: '/more',
                                screens: {
                                    '/company/home/more/default': '',
                                    '/company/home/more/repository': '/repository',
                                    '/company/home/more/knowledge-base': '/knowledge-base',
                                    '/company/home/more/members': '/members',
                                    '/company/home/more/settings': {
                                        path: '/settings',
                                        screens: {
                                            '/company/home/more/settings/default': '',
                                        },
                                    },
                                    '/company/home/more/disconnect': '/disconnect',
                                },
                            },
                        },
                    },
                    '/company/profile': {
                        path: '/profile',
                        screens: {
                            '/company/profile/user-profile': {
                                path: '/user-profile',
                                screens: {
                                    '/company/profile/user-profile/default': '',
                                    '/company/profile/user-profile/my-informations': '/my-informations',
                                    '/company/profile/user-profile/email-address': {
                                        path: '/email-address',
                                        screens: {
                                            '/company/profile/user-profile/email-address/default': '',
                                            '/company/profile/user-profile/email-address/verify-code-user-profile': '/verify-code-user-profile',
                                        },
                                    },
                                    '/company/profile/user-profile/mobile-phone': {
                                        path: '/mobile-phone',
                                        screens: {
                                            '/company/profile/user-profile/mobile-phone/default': '',
                                            '/company/profile/user-profile/mobile-phone/verify-mobile-code-user-profile': '/verify-mobile-code-user-profile',
                                        },
                                    },
                                    '/company/profile/user-profile/change-password': '/change-password',
                                    '/company/profile/user-profile/notifications': '/notifications',
                                },
                            },
                            '/company/profile/other-user-profile': '/other-user-profile',
                        },
                    },
                    '/company/event-details': '/event-details',
                    '/company/document-details': '/document-details',
                    '/company/boardpack': '/boardpack',
                },
            },
            '/splash-screen': '/splash-screen',
            '/impersonate': '/impersonate',
            '/portal/companies/:companyId/events/:eventId/documents': '/portal/companies/:companyId/events/:eventId/documents',
        },
    };
    const linking = {
        prefixes: [
            'https://app.govrn.com',
            'be.govrn.mobile://',
            'http://localhost:8080',
        ],
        config,
    };
    // Render
    return (<Suspense fallback={<LoadingAnimation />}>
      <ChakraProvider theme={theme}>
        <I18nextProvider i18n={getI18nInstance()}>
        <NavigationContainer linking={linking} fallback={<LoadingAnimation />}>
            <QueryClientProvider client={queryClientRef.current}>
              <TokenStatus>
                <CompaniesContextProvider>
                  <NavigationTree route={getDefaultRoute(routes)}/>
                </CompaniesContextProvider>
              </TokenStatus>
            </QueryClientProvider>
          </NavigationContainer>
        </I18nextProvider>
      </ChakraProvider>
    </Suspense>);
};
function NavigationTree({ route, parentRoute, }) {
    // Attributes
    const { t } = useTranslation();
    const { selectedEnrolment } = useCompaniesContext();
    // Derived Attributes
    const childRoutes = route.subRoutes;
    const currentPath = parentRoute && parentRoute !== '/'
        ? `${parentRoute}${route.name}`
        : route.name;
    // Leaf Node
    if (!childRoutes || childRoutes.length === 0) {
        const Component = route.component;
        return <Component />;
    }
    // Navigator Node
    const { Navigator, Screen } = route.component;
    return (<Navigator initialRouteName={currentPath !== '/'
            ? `${currentPath}${route.defaultRoute}`
            : route.defaultRoute} sceneContainerStyle={{ backgroundColor: 'transparent' }} screenOptions={{ headerShown: false }}>
      {childRoutes.map(child => {
            const screenKey = currentPath !== '/' ? `${currentPath}${child.name}` : child.name;
            const ChildNavigationTree = () => (<NavigationTree route={child} parentRoute={currentPath}/>);
            const ChildTree = ChildNavigationTree();
            const ChildTreeComponent = () => ChildTree;
            function documentTitle() {
                const title = child?.name.replace('/', '');
                if (title === 'default') {
                    const currentPathSplitted = currentPath?.split('/');
                    const lastParentName = currentPathSplitted[currentPathSplitted?.length - 1];
                    return lastParentName;
                }
                return title;
            }
            return (<Screen key={screenKey} name={screenKey} component={ChildTreeComponent} options={{
                    lazy: true,
                    title: selectedEnrolment
                        ? `${selectedEnrolment?.company?.name} | ${t(child.tabBarLabel)}`
                        : _.capitalize(documentTitle()),
                }}/>);
        })}
    </Navigator>);
}
export default App;
