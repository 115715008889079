import { StyleSheet } from 'react-native';
import COLORS from '../../../../../constants/colors';
const styles = StyleSheet.create({
    mainContainer: {
        backgroundColor: COLORS.White.T500.value,
        alignItems: 'center',
        flexDirection: 'row',
        borderRadius: 8,
    },
    searchReset: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    searchIconStyle: {
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 15,
        marginBottom: 15,
        marginLeft: 15,
    },
    input: {
        backgroundColor: 'transparent',
        width: 280,
        flex: 1,
        borderWidth: 0,
    },
});
export default styles;
