import { useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFilePicker } from 'use-file-picker';
import { useCompaniesContext } from '../../../../contexts/companies';
import useRouter from '../../../../hooks/router/useRouter';
import { useReadDocumentsV2, useReadDocumentTypes, } from '../../../../services/online/repository/repository';
import { DocumentGroupBy, DocumentRelation, Role, SortBy, } from '../../../../services/types';
import { tKeys } from '../../../../translations/keys';
import { isMinute } from '../../../../utils/getDocumentKind';
import handleSelectDocument from '../../../../utils/handleSelectDocument';
import { onSelectedFileSuccess } from '../../../../utils/selectFile.web';
import { DocumentKindEnum, } from '../../../document-details/type';
import { ListLayoutEnum } from './type';
const useLogic = () => {
    // Attributes
    const { push, params } = useRouter();
    const { t } = useTranslation();
    const { selectedCompanyId, selectedEnrolment } = useCompaniesContext();
    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState();
    const { isOpen: isOpenCreateDocument, onOpen: onOpenCreateDocument, onClose: onCloseCreateDocument, } = useDisclosure();
    const [documentTypesFilter, setDocumentTypesFilter] = useState(null);
    const translatedDocumentTypesFilter = documentTypesFilter?.map(option => ({
        ...option,
        label: t(option.label),
    }));
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const [listLayoutType, setListLayoutType] = useState(ListLayoutEnum.ROW);
    // Entity
    const repositoryFiltersEntity = params?.repositoryFiltersEntity ?? {
        fromDate: undefined,
        period: undefined,
        toDate: undefined,
        selectedFileTypes: documentTypesFilter,
    };
    const optionsOrder = [
        {
            label: t(tKeys.repository.order.last_issued_on_last),
            value: SortBy?.[0],
        },
        {
            label: t(tKeys.repository.order.last_issued_on_first),
            value: SortBy?.[1],
        },
        {
            label: t(tKeys.filter.order.alphabetically.desc),
            value: SortBy?.[2],
        },
        {
            label: t(tKeys.filter.order.alphabetically.asc),
            value: SortBy?.[3],
        },
    ];
    const optionsGroupBy = [
        {
            label: t(tKeys.filter.order_by.document.all_documents),
            value: DocumentGroupBy?.[0],
        },
        {
            label: t(tKeys.filter.order_by.document.by_file_type),
            value: DocumentGroupBy?.[1],
        },
        {
            label: t(tKeys.filter.order_by.document.by_date),
            value: DocumentGroupBy?.[2],
        },
        {
            label: t(tKeys.filter.order_by.document.by_event),
            value: DocumentGroupBy?.[3],
        },
        {
            label: t(tKeys.filter.order_by.document.by_event_type),
            value: DocumentGroupBy?.[4],
        },
    ];
    const [selectedOptionGroupBy, setSelectedOptionGroupBy] = useState(optionsGroupBy[1]);
    // Queries
    // - Getters
    const { openFilePicker } = useFilePicker({
        readAs: 'DataURL',
        accept: [
            'image/jpeg',
            'image/png',
            'image/gif',
            '.txt',
            '.pdf',
            '.doc',
            '.docx',
            '.ppt',
            '.pptx',
            '.xls',
            '.xlsx',
            '.csv',
        ],
        onFilesSelected: async (chosenDocuments) => await onSelectedFileSuccess(chosenDocuments, handleOnSelectFile, onOpenCreateDocument),
    });
    const { data: documentTypes } = useReadDocumentTypes({
        'company-id': selectedCompanyId,
    }, { query: { enabled: !!selectedCompanyId } });
    const documentSources = [
        {
            value: DocumentRelation[2],
            label: t(tKeys.filter.document_source.general_documents),
        },
        {
            value: DocumentRelation[1],
            label: t(tKeys.filter.document_source.from_event),
        },
        {
            value: DocumentRelation[3],
            label: t(tKeys.filter.document_source.to_sign),
        },
    ];
    const [selectedDocumentTypes, setSelectedDocumentTypes] = useState([]);
    const [selectedDocumentRelation, setSelectedDocumentRelation] = useState(documentSources[0]);
    const [fromDate, setFromDate] = useState(null);
    const [isFromPopoverOpen, setIsFromPopoverOpen] = useState(false);
    const [toDate, setToDate] = useState(null);
    const [isToPopoverOpen, setIsToPopoverOpen] = useState(false);
    const [orderBy, setOrderBy] = useState(optionsOrder?.[1]);
    const { data, isLoading: isDocumentDataLoading, refetch: refetchDocumentData, } = useReadDocumentsV2({
        'company-id': selectedCompanyId,
        'group-by': selectedOptionGroupBy?.value,
        'sort-by': orderBy?.value,
        'date-from': fromDate?.toISOString(),
        'date-to': toDate?.toISOString(),
        'document-types': selectedDocumentTypes?.length !== 0
            ? selectedDocumentTypes?.map(type => type.id)
            : documentTypesFilter?.map(type => type.id),
        'get-uri': false,
        'get-thumbnail-uri': false,
        'document-relation': selectedDocumentRelation?.value,
    }, {
        query: {
            enabled: !!selectedCompanyId &&
                !!repositoryFiltersEntity?.selectedFileTypes?.length &&
                (selectedDocumentTypes?.length !== 0 ||
                    documentTypesFilter.length !== 0) &&
                !!selectedOptionGroupBy &&
                !!documentTypesFilter &&
                !!orderBy?.value !== undefined,
        },
    });
    const documentsData = useMemo(() => data
        ? data?.map(d => {
            return { title: d?.categoryName, data: d.items };
        })
        : [], [data]);
    const selectedRepositoryFilters = useMemo(() => {
        const isAllFileTypeSelected = repositoryFiltersEntity?.selectedFileTypes?.length ===
            documentTypes?.length;
        const isPeriodSelected = !!repositoryFiltersEntity?.period;
        const allFilters = Object.values(repositoryFiltersEntity).filter(value => !!value)?.length;
        return (allFilters + (isAllFileTypeSelected && -1) + (isPeriodSelected && -2));
    }, [repositoryFiltersEntity]);
    // Functions
    function refetchDocuments() {
        void refetchDocumentData();
    }
    function handleOnSelectFile(value) {
        setSelectedFile(value);
    }
    function handleOnGroupByChange(groupBy) {
        setSelectedOptionGroupBy(groupBy);
    }
    function onLayoutIconPress() {
        setListLayoutType(listLayoutType === ListLayoutEnum.GRID
            ? ListLayoutEnum.ROW
            : ListLayoutEnum.GRID);
    }
    function handleOnPressTab(activeKey) {
        setSelectedTab(activeKey);
        setSelectedDocumentRelation(documentSources[activeKey]);
        switch (activeKey) {
            case DocumentRelation[1]:
                setSelectedOptionGroupBy(optionsGroupBy[4]);
                break;
            case DocumentRelation[0]:
                setSelectedOptionGroupBy(optionsGroupBy[1]);
                break;
            default:
                setSelectedOptionGroupBy(optionsGroupBy[0]);
                break;
        }
    }
    function onDocumentPress(document) {
        const documentParams = {
            documentKind: isMinute(document)
                ? DocumentKindEnum.MINUTES
                : DocumentKindEnum.DOCUMENT,
            documentId: document.id,
        };
        push('/company/document-details', {
            companyId: selectedCompanyId,
            ...documentParams,
        });
    }
    function handleOnDocumentTypeFilterChange(documentTypes) {
        setSelectedDocumentTypes(documentTypes);
    }
    function handleOnDocumentRelationChange(documentSource) {
        setSelectedDocumentRelation(documentSource);
    }
    function handleOnSetFromDateChange(fromDate) {
        setFromDate(fromDate);
    }
    function onToggleFromPopOver() {
        setIsFromPopoverOpen(!isFromPopoverOpen);
    }
    function handleOnSetToDateChange(toDate) {
        setToDate(toDate);
    }
    function onToggleToPopOver() {
        setIsToPopoverOpen(!isToPopoverOpen);
    }
    function handleOnPressAddDocument() {
        openFilePicker();
    }
    function handleOnCloseModal() {
        setShowModal(false);
    }
    async function handleOnSelectDocument(documentType) {
        await handleSelectDocument(documentType, push, handleOnCloseModal, '/create-document/default');
    }
    function onSearchPress() {
        push('/search');
    }
    const canUploadDocument = selectedEnrolment?.role === Role[0] || selectedEnrolment?.role === Role[1];
    // Effects
    useEffect(() => {
        if (documentTypes) {
            const documentTypeFilter = documentTypes?.map(documentType => {
                return {
                    label: documentType?.name,
                    value: documentType?.name,
                    id: documentType.id,
                    isSelected: true,
                };
            });
            // Set the state
            setDocumentTypesFilter(documentTypeFilter);
            // innitially select all document types
            // setSelectedDocumentTypes(documentTypeFilter);
        }
    }, [documentTypes]);
    // Refetch anytime the params are changing
    useEffect(() => {
        if (params)
            void refetchDocumentData();
    }, [params]);
    // Return
    return {
        t,
        documentsData,
        isDocumentDataLoading,
        documentTypesFilter,
        onLayoutIconPress,
        listLayoutType,
        refetchDocumentData,
        isDropdownOpen,
        setDropdownOpen,
        optionsOrder,
        selectedOptionGroupBy,
        optionsGroupBy,
        handleOnPressTab,
        selectedTab,
        onDocumentPress,
        selectedRepositoryFilters,
        handleOnPressAddDocument,
        showModal,
        handleOnCloseModal,
        handleOnSelectDocument,
        onSearchPress,
        isOpenCreateDocument,
        onCloseCreateDocument,
        selectedFile,
        refetchDocuments,
        canUploadDocument,
        setOrderBy,
        orderBy,
        translatedDocumentTypesFilter,
        documentSources,
        handleOnDocumentTypeFilterChange,
        handleOnDocumentRelationChange,
        handleOnSetFromDateChange,
        fromDate,
        onToggleFromPopOver,
        isFromPopoverOpen,
        handleOnSetToDateChange,
        toDate,
        onToggleToPopOver,
        isToPopoverOpen,
        handleOnGroupByChange,
        selectedDocumentRelation,
        selectedDocumentTypes,
    };
};
export default useLogic;
