import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlatList } from 'react-native';
import LoadingWrapper from '../../components/utility/loading-wrapper';
import Placeholder from '../../components/utility/placeholder';
import COLORS from '../../constants/colors';
import { tKeys } from '../../translations/keys';
import SearchListHeader from './components/content/search-list-header';
import SearchResultCard from './components/content/search-result-card';
import useLogic from './logic.web';
import styles from './style';
import { Divider, Stack, useOutsideClick, } from '@chakra-ui/react';
import PostDetails from '../post-details/index.web';
import Page from '../../components/surfaces/page';
import NavigationHeader from '../../components/navigation/navigation-header';
import Text from '../../components/content/text';
import FONTS from '../../constants/fonts';
import SearchInput from './components/inputs/search-input/index.web';
import Container from '../../components/surfaces/container';
const Search = () => {
    // Attributes
    const { t } = useTranslation();
    const { search, handleSearch, activeFilters, resultsTotal, onFilterPress, searchResult, isLoading, total, onSearchResultPress, isOpenPopover, handleOnClickInput, isOpenPostDetails, onClosePostDetails, postId, handleOnClosePopover, popoverRef, } = useLogic();
    useOutsideClick({
        ref: popoverRef,
        handler: () => setTimeout(() => isOpenPopover && handleOnClosePopover(), 100), // Put a timeout resolve the close of the popover when click on the button
    });
    // Render Item
    const renderItem = ({ item }) => {
        return (<SearchResultCard searchResult={item} 
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onPress={async () => await onSearchResultPress(item)}/>);
    };
    const renderListHeader = () => {
        if (search?.length > 2)
            return (<Stack p={0}>
          <SearchListHeader total={total} activeFilters={activeFilters} resultsTotal={resultsTotal} onFilterPress={onFilterPress} search={search}/>
          <Divider />
        </Stack>);
    };
    // Render ListEmptyComponent
    const renderPlaceholder = () => {
        console.log('searchResult', searchResult);
        if (search?.length < 3) {
            return (<Placeholder imageType="monocle-emoji" title={t(tKeys.search.placeholder.no_results.title)} subTitle={t(tKeys.search.placeholder.no_results.subtitle)} maxWidth={320}/>);
        }
        if (!isLoading && total === 0 && searchResult?.length === 0)
            return (<Placeholder imageType="think-emoji" title={t(tKeys.search.placeholder.no_search_terms.title)} subTitle={t(tKeys.search.placeholder.no_search_terms.subtitle)} maxWidth={210}/>);
    };
    // Render
    return (<Page showSideBar barStyle="dark-content" backgroundColor={COLORS.BG.value}>
      <NavigationHeader justifyTitle="flex-start" style={{ minHeight: '3.75rem' }}>
        <Text font={FONTS.T1.T16Px.Bold700.value} color={COLORS.Text.T400.value}>
          {t(tKeys.common.search)}
        </Text>
      </NavigationHeader>
      <Container style={{
            marginTop: '1rem',
            marginHorizontal: 'auto',
            width: '50rem',
            maxHeight: '100%',
        }}>
        <SearchInput name="searchGlobal" value={search} onChange={handleSearch} placeholder={t(tKeys.search.global.placeholder)} style={{ height: 60 }} autofocus={true}/>
        <FlatList data={null} renderItem={null} style={{
            marginTop: '1rem',
            paddingVertical: '1rem',
            maxHeight: '100%',
            width: '50rem',
            overflow: 'auto',
            paddingHorizontal: '1rem',
            borderRadius: '0.5rem',
            backgroundColor: COLORS.White.T500.value,
        }} ListEmptyComponent={renderPlaceholder} ListHeaderComponent={renderListHeader} ListFooterComponent={<LoadingWrapper isLoading={isLoading && search?.length > 2}>
              {total > 0 && (<FlatList data={searchResult} style={{ paddingVertical: '.5rem' }} keyExtractor={(_, index) => `searchResult${index}`} renderItem={renderItem} contentContainerStyle={styles.contentContainer}/>)}
            </LoadingWrapper>}/>
      </Container>

      {/* <Popover
          placement="bottom-end"
          isOpen={isOpenPopover}
          autoFocus={false}
          onClose={handleOnClosePopover}
          closeOnBlur
        >
          <PopoverTrigger>
            <Button
              variant={'ghost'}
              _hover={{ backgroundColor: 'transparent' }}
              onClick={handleOnClickInput}
              isDisabled={isOpenPopover}
              _disabled={{ opacity: 1 }}
              px={0}
            >
              <SearchInput
                name="searchGlobal"
                value={search}
                onChange={handleSearch}
                placeholder={t(tKeys.search.global.placeholder)}
                style={{ height: 40 }}
              />
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent
              width={'fit-content'}
              borderColor={COLORS.Line.value}
              boxShadow={SHADOWS.Sidebar.value}
              overflow={'hidden'}
              ref={popoverRef}
            >
              <PopoverBody>
                
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover> */}
      {/* Modals */}
      <PostDetails isOpen={isOpenPostDetails} onClose={onClosePostDetails} postId={postId}/>
    </Page>);
};
export default Search;
