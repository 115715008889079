import React from 'react';
import { View } from 'react-native';
import styles from './style.web';
import { Box, Input } from '@chakra-ui/react';
import Icon from '../../../../../components/content/icon';
import Container from '../../../../../components/surfaces/container';
import FONTS from '../../../../../constants/fonts';
function SearchInput({ onChange, value, isClearable, placeholder, onPressClear, style, isDisabled, testID, autofocus, iconSize = 16, textFont, }) {
    // Render
    return (<View style={{
            ...styles.mainContainer,
            ...style,
        }}>
      {/* Search icon */}
      <Container style={styles.searchIconStyle}>
        <Icon name="search" width={iconSize} height={iconSize}/>
      </Container>

      {/* Text */}
      <Input name={'searchInput'} value={value} onChange={e => onChange(e?.currentTarget?.value)} placeholder={placeholder} font style={{
            outline: 'none',
            fontSize: '1.5rem',
            fontWeight: FONTS.T1.T16Px.Bold700.value.fontWeight,
            ...styles.input,
        }} isDisabled={isDisabled} autoFocus={autofocus} _focusVisible={{ borderWidth: 0 }}/>

      {/* Reset button */}
      {isClearable && (<Box onClick={onPressClear} style={styles.searchReset} paddingRight={'1rem'}>
          <Icon name="search-reset" width={20} height={20}/>
        </Box>)}
    </View>);
}
export default SearchInput;
