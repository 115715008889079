import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationHeader from '../../../../components/navigation/navigation-header';
import Page from '../../../../components/surfaces/page';
import COLORS from '../../../../constants/colors';
import { tKeys } from '../../../../translations/keys';
import { StackDivider, VStack } from '@chakra-ui/react';
import ManageEvents from './manage-events/index.web';
import ManageFiles from './manage-files/index.web';
import CompanyDetails from './manage-company-details/index.web';
import OtherSettings from './other-settings/index.web';
import ManageBoardRoles from './manage-board-roles/index.web';
const Settings = () => {
    // Attributes
    const { t } = useTranslation();
    // Render
    return (<Page barStyle="dark-content" backgroundColor={COLORS.White.T500.value} showSideBar>
      <NavigationHeader style={{ minHeight: '3.75rem' }}>
        {t(tKeys.common.settings)}
      </NavigationHeader>
      <VStack width={'full'} height={'full'} alignItems={'flex-start'} bgColor={COLORS.BG.value} px={'2rem'} py={'1.5rem'} overflow={'auto'} divider={<StackDivider />} spacing={'2rem'}>
        <ManageEvents />
        <ManageFiles />
        <ManageBoardRoles />
        <CompanyDetails />
        <OtherSettings />
      </VStack>
    </Page>);
};
export default Settings;
